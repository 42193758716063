.khambenh-ngoaitrukb-form-input-label {
    font-size: 12px;
}

.khambenh-ngoaitrukb-form-input-label-tthc {
    font-size: 12px;
    width: 60px;
}

.khambenh-ngoaitrukb-form-input-label-left {
    font-size: 12px;
    width: 90px;
}

.khambenh-HSBAKP-title {
    background-color: #0067ac;
    color: white;
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 13px;
}
.mainHSNTKB{
    width: 98.5%;
    margin: 0 auto;
}
.mainHSNTKB .ant-select-single {
    height: 20px !important;
}

.mainHSNTKB .ant-select-arrow {
    top: 50% !important;
}

.mainHSNTKB .HSBANT_row {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
}
.mainHSNTKB .HSBANT_row  label{
    margin-top: 3px;
}
.mainHSNTKB .HSBANT_row .xulythuoc{
    margin-top: 15px;
}
.mainHSNTKB .HSBANT_row .ant-input-outlined{
    height: 21px;
}
.mainHSNTKB .HSBANT_row .tongkettext{
    margin-top: 10px;
}