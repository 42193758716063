.canlamsan-form-input-label {
    width: 70px;
}
.doctraketqua-icon {
    display: flex;
    justify-content: center;
    border: 1px solid black;
    margin: 0px 1px;
    padding: 2px;
    width: 24px;
}
