//form
/*.tiepnhan-timkiem-form {
    border: 2px solid #0067AC;
    padding: 6px;

}*/
.tiepnhan-timkiem-form input{
    height: 26px;
}
.tiepnhan-timkiem-form label{
    margin-top: 4px;
}
