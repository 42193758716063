//layout main
/*.bg-frame {
    background: rgb(0, 103, 172);
    background: linear-gradient(157deg, rgba(0, 103, 172, 1) 22%, rgba(218, 10, 76, 1) 78%);
    height: fit-content !important;
}*/
.b-content {
    margin: 2px;
    // height: fit-content !important;
}
span.anticon.anticon-menu-fold {
    color: aliceblue;
}
span.anticon.anticon-menu-unfold {
    color: aliceblue;
}

.ant-menu-item-selected {
    background-color: #0067ac !important;
    color: white !important;
    margin: 2px !important;
    border-radius: 10px !important;
    font-weight: bold !important;
}
ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.rounded.b-header.css-dev-only-do-not-override-pr0fja {
    line-height: 30px;
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
    padding: 0px 6px;
}

//img logo dọc
.img-logo {
    width: 30px;
    height: 30px;
    // text-align: center;
}

//icon open-close menu
.menu-box-icon {
    position: absolute;
    left: 0px;
    top: 0px;
    transition: 0.2s ease-in-out;
}
.menu-box-icon-click {
   position: absolute;
    right: 2px;
    top: 8px;
    transition: 0.2s ease-in-out;
}
.menu-box-icon-click button{
    float: right;
}

.menu-btn-icon {
    font-size: "16px";
    width: 40px;
    height: 30px;
    background-color: #0067ac;

    align-items: "center";
    display: "flex";
    justify-content: "center";
    &:hover {
        border: rgb(12, 106, 170) solid 1px;
        background-color: #3897d6 !important;
    }
}
.menu-btn-icon-avtive {
    font-size: "16px";
    width: 40px;
    height: 30px;
    background-color: #ffffff;
    align-items: "center";
    display: "flex";
    opacity: 0.6;
    justify-content: "center";
    z-index: 1;
    svg {
        color: #000 !important;
    }
    &:hover {
        border: rgb(12, 106, 170) solid 1px;
        opacity: 1;
        background-color: #3897d6 !important;
        svg {
            color: #ffffff !important;
        }
    }
}

//menu
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 23px !important;
}
.ant-menu-submenu-title {
    height: 30px !important;
}
//navlink
.menu-navlink {
    text-decoration: none;
    font-weight: bold;
    font-size: 14px !important;
}
.menu-navlink-child {
    text-decoration: none;
    font-weight: 500;
    font-size: 13px !important;
}
.scroll-menu {
    overflow-y: scroll;
    height: 95vh;
}

.scroll-menu::-webkit-scrollbar {
    display: none;
}
.scroll-menu {
    scrollbar-width: none;
}
//
.login-user {
    position: relative;
    bottom: 0px;
    left: 0px;
    width: 100%;
    .user-name {
        width: 16%;
        position: fixed;
        bottom: 5px;
        left: 3px;
        text-align: center;
        font-size: 13px;
        cursor: pointer;
        color: #0067ac;
        border: 1px solid silver;
        padding: 3px 25px;
    }
}
.over-link {
    display: --webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bg-light.b-menu{
    border-right: 2px solid #0067ac;
    background: #fff !important;
}
.ant-menu-submenu-inline{
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0px !important;
}
.ant-menu .ant-menu-submenu-title{
    border-radius: 0px !important;

}
