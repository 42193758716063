.vienphi-danhmuc-title {
    font-weight: bold;
}

//icon
.vienphi-danhmuc-icon-modify {
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
}

//
.scroll {
    overflow-y: scroll;
    // height: 100vh;
}

.scroll::-webkit-scrollbar {
    display: none;
}

.scroll {
    scrollbar-width: none;
}

//
.vienphi-danhmuc-btn {
    background-color: #0067ac;
    color: #fff;
}

.vienphi-danhmuc-add {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 5px 10px;
}

.danhmuc thead.ant-table-thead tr {
    line-height: 25px !important;
}

.danhmuc .ant-table-tbody>tr>td {
    line-height: 30px;
    border-right: 1px solid #f0f0f0;
}

.danhmuc_nhomvpht .ant-table-tbody>tr>td:nth-child(4) {
    width: 100px;
}

.danhmuc_nhomvppk .ant-table-tbody>tr>td:nth-child(5) {
    width: 100px;
}

.ant-table-wrapper .ant-table-cell-fix-right {
    width: 100px !important;
}

.ant-modal .ant-modal-close {
    top: 7px !important;
}

.inputdm {
    height: 30px;
}
.lablename{
    margin-top: 16px;
}
.lablename-input{
    margin-top: 10px;
}

 .dmdot .ant-form-item .ant-form-item-label >label::after {
    content: "";
 }
.dmname-w .ant-col{
    width: 120px;
 }



.ant-form-item-control-input {
    min-height: 40px !important;
}

.dmdot .ant-select-selector {
    margin: 6px 0px !important;
    height: 27px !important;
}

.ant-select-arrow {
    top: 70% !important;
}
.dmvp-1 .lablename-input, .dmvp-1  .lablename{
    width: 120px;
}
.dmvp-1 .lablename-9324_A{
    width: 200px;
}