.btn-nhapkho {
    position: relative;
}

.btn-pos {
    position: absolute;
    right: 0;
    top: -30px;
}

.btn-pos button {
    float: left;
}

.btn-pos .form-btn {
    padding: 10px 10px !important;
}
.title-nhapkho{
    font-size: 14px;
    font-weight: bold;
    padding: 10px 10px;
}
.tt-xuathaophipk{
    background: #f5f5f5;
    padding-top: 10px;
    padding-left: 10px;
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
}
.tt-xuathaophipk .lablename-nhapkho {
    font-size: 12px;
    float: left;
    width: 40%;
    text-align: right;
    padding-right: 5px;
}

.tt-xuathaophipk .txtinput {
    font-size: 12px;
    float: left;
    width: 60%;
}
.tt-xuathaophipk .lablename-nhapkho.ghichu {
    width: 6.7%;
}

.tt-xuathaophipk .txtinput.ghichu {
    width: 93.3%;
}


.tt-xuathaophipk .row>* {
    padding: 0px 1px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.tt-xuathaophipk .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.row-m {
    margin-top: 5px !important;
    margin-bottom: 5px;
}
