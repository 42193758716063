.danhsachleft-btn {
    svg {
        font-size: 10px;
    }
    border-radius: 0px;
    padding: 0px;
    width: 38px;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.khambenh-danhsach-left {
    position: relative;
}
.khambenh-danhsach-open-left {
    width: 30px;
    height: 50px;
    z-index: 3;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248, 0.5);
    position: absolute;
    right: -30px;
    top: 180px;
    transition: all 0.5s ease-out;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}

.khambenh-danhsach-close-left {
    position: absolute;
    right: 0px;
    top: 180px;
    transition: all 1s ease-out;
    width: 30px;
    height: 50px;
    position: absolute;
    z-index: 3;
    left: 0;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 180px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248, 0.5);
    transition: all ease-in;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}
.khambenh-danhsach-left-close {
    transition: all 1s ease-out;
    display: none;
}
.khambenh-danhsach-left-open {
    transition: all 1s ease-out;
}
// .scroll-dsleft {
//     overflow-y: scroll;
//     // height: 100vh;
// }
// .scroll-dsleft::-webkit-scrollbar {
//     display: none;
// }
// .scroll-dsleft {
//     scrollbar-width: none;
// }
