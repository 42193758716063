.label-table-collapse {
    display: flex;
    border-collapse: collapse;
    div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    div {
        padding: 3px 10px;
        font-size: 12px;
        color: #0067ac !important;
        background-color: #ebebeb !important;
        font-weight: bold;
        border: 1px solid silver;
    }
}
.table-collapse {
    .ant-table-body {
        overflow-y: scroll !important;
    }
    .ant-table-body::-webkit-scrollbar {
        display: none !important;
    }
    .ant-table-body {
        scrollbar-width: none;
    }
}
.h-max-boketquaxetnghiem {
    height: 320px;
    .scroll {
        overflow-y: scroll;
        height: 315px;
    }
    .scroll::-webkit-scrollbar {
        display: none;
    }
    .scroll {
        scrollbar-width: none;
    }
}
