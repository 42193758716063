.vienphi-danhsachchothu-text-bhyt {
    color: red;
    font-weight: bold;
}

// lable input
.vienphi-phieuthutamung-danhsachchothu-form-input-label {
    font-size: 12px;
    width: 50px;
}
.vienphi-phieuthutamung-danhsachchothu-form-input-label-5nam {
    font-size: 12px;
    width: 80px;
}
.vienphi-phieuthutamung-danhsachchothu-form-input-label-noidk {
    font-size: 12px;
    width: 80px;
}
.vienphi-phieuthutamung-danhsachchothu-form-input-label-right {
    font-size: 12px;
    width: 80px;
}
.vienphi-danhsach-left {
    position: relative;
}
.vienphi-danhsach-open-left {
    width: 30px;
    height: 50px;
    z-index: 3;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248);
    position: absolute;
    right: -30px;
    top: 180px;
    transition: all 0.5s ease-out;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}

.vienphi-danhsach-close-left {
    position: absolute;
    right: 0px;
    top: 180px;
    transition: all 1s ease-out;
    width: 30px;
    height: 50px;
    position: absolute;
    z-index: 3;
    left: 0;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 180px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248);
    transition: all ease-in;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}
.vienphi-danhsach-left-close {
    transition: all 1s ease-out;
    display: none;
}
.vienphi-danhsach-left-open {
    transition: all 1s ease-out;
}
.vienphi-border-line {
    border-left: 1px solid silver;
    padding-left: 10px;
}
.vp-phieuthutamung-bottom .vaovien{
    font-size: 12px;
    margin-top: 5px;
}
.chothu-tamung-bottom {
    border-top: 1px solid #ccc;
    padding-bottom: 5px;
    margin-top: 10px;
}
.chothu-tamung-bottom label::after{
    content: "" !important;
}
.chothu-tamung-bottom .checkintoa-name{
    margin-top: 13px;
}
.vienphi-border-tamung{
    border: 2px solid #0067ac;
}