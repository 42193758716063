.danhsachleft-btn {
    svg {
        font-size: 10px;
    }
    border-radius: 0px;
    padding: 0px;
    width: 38px;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.danhsachleft-input-label{
    width: 30px;
}