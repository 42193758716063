//form
.tiepnhan-timkiem-form {
    border: 2px solid #0067ac;
    padding: 6px;
}

.tiepnhan-row{
   margin: 5px 5px;
}

.m-1.tn-from{
    margin: 0px !important;
}
.b-content{
    margin: 0px !important;
}