.btn-icon {
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
}
li.ant-dropdown-menu-item {
    padding: 2px 10px !important;
}

.danhsach-left {
    position: relative;
}
.danhsach-open-left {
    width: 30px;
    height: 50px;
    z-index: 3;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248, 0.5);
    position: absolute;
    right: -30px;
    top: 180px;
    transition: all 0.5s ease-out;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}

.danhsach-close-left {
    position: absolute;
    right: 0px;
    top: 180px;
    transition: all 1s ease-out;
    width: 30px;
    height: 50px;
    position: absolute;
    z-index: 3;
    left: 0;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 180px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248, 0.5);
    transition: all ease-in;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}
.danhsach-left-close {
    transition: all 1s ease-out;
    display: none;
}
.danhsach-left-open {
    transition: all 1s ease-out;
}
