.duoc-form-input-label {
    width: 65px;
}

.box {
    position: relative;
}
.box-table-search {
    position: absolute;
    z-index: 1;
    .table-search {
        background-color: #ffffff;
        border: 1px solid black;
        padding: 5px;
    }
}

.label-table {
    display: flex;
    border-collapse: collapse;
    div {
        padding: 3px 5px;
        font-size: 12px;
        color: #0067ac !important;
        background-color: #ebebeb !important;
        font-weight: bold;
        border: 1px solid silver;
    }
}

.table-duoc {
    z-index: 0;
    .ant-table-body {
        overflow-y: scroll !important;
    }
    .ant-table-body::-webkit-scrollbar {
        display: none !important;
    }
    .ant-table-body {
        scrollbar-width: none;
    }
}
.h-max-duoc {
    height: 480px;
    .scroll {
        overflow-y: scroll;
        height: 480px;
    }
    .scroll::-webkit-scrollbar {
        display: none;
    }
    .scroll {
        scrollbar-width: none;
    }
}


.box {
    position: relative;
}
.box-table-search {
    position: absolute;
    z-index: 1;
    top: 25px;
    .table-search {
        background-color: #ffffff;
        border: 1px solid rgb(203, 203, 203);
        padding: 5px;
    }
}

.label-table {
    display: flex;
    border-collapse: collapse;
    div {
        padding: 3px 5px;
        font-size: 12px;
        color: #0067ac !important;
        background-color: #ebebeb !important;
        font-weight: bold;
        border: 1px solid silver;
    }
}
.thduoc {
    display: flex;
    div {
        padding: 3px 5px;
        font-size: 12px;
        color: #0067ac !important;
        background-color: #ebebeb !important;
        font-weight: bold;
        border: 1px solid silver;
    }
}
// 
.h-max-duoc {
    height: 428px;
    .scroll {
        overflow-y: scroll;
        height: 370px;
    }
    .scroll::-webkit-scrollbar {
        display: none;
    }
    .scroll {
        scrollbar-width: none;
    }
}
.div-box-search {
    width: 700px;
    .bg-active {
        background-color: #0067ac;
        color: #ffffff;
    }
    .bg-unactive {
        background-color: #ffffff;
        color: #000000;
        &:hover {
            border: 1px solid #0067ac;
        }
    }
    .scroll {
        overflow-y: scroll;
        height: fit-content;
        max-height: 100px;
    }
    .scroll::-webkit-scrollbar {
        display: none;
    }
    .scroll {
        scrollbar-width: none;
    }
}
