.btn-nhapkho {
    position: relative;
}

.btn-pos {
    position: absolute;
    right: 0;
    top: -30px;
}

.btn-pos button {
    float: left;
}

.btn-pos .form-btn {
    padding: 10px 10px !important;
}
.title-nhapkho{
    font-size: 14px;
    font-weight: bold;
    padding: 10px 10px;
}
.tt-dutruphieulinhthuoc{
    background: #f5f5f5;
    padding-top: 10px;
    padding-left: 10px;
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
}
.tt-dutruphieulinhthuoc .lablename-nhapkho {
    font-size: 12px;
    float: left;
    width: 27%;
    text-align: right;
    padding-right: 5px;
}

.tt-dutruphieulinhthuoc .txtinput {
    font-size: 12px;
    float: left;
    width: 73%;
}

.tt-dutruphieulinhthuoc .lablename-nhapkho.ghichu {
    width: 6.7%;
}

.tt-dutruphieulinhthuoc .txtinput.ghichu {
    width: 93.3%;
}


.tt-dutruphieulinhthuoc .row>* {
    padding: 0px 1px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.tt-dutruphieulinhthuoc .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.row-m {
    margin-top: 5px !important;
    margin-bottom: 5px;
}
.btn-tim .form-btn{
    height: 20px;
    font-size: 12px;
    padding: 5px 15px;
}
.btn-tim .form-btn:hover{
    background: #0067ac;
    color: #fff;
}
