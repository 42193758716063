.bg-login {
    background: url("../../assets/image/bglogin.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.box-login {
    width: 30%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin-right: 130px;
    .title {
        display: flex;
        align-items: center;
        background-color: rgb(0, 103, 172,0.9);
        padding: 15px;
        div {
            font-size: 17px;
            color: #fff;
            margin: 0px 10px;
            font-weight: bold;
        }
    }
    .content {
        border: 1px solid #0067ac;
        background-color: rgb(255, 255, 255, 0.7);
        padding-bottom: 40px;
        .box-input {
            padding: 40px 40px 0px 10px;
        }
    }
    .select-login {
        .ant-select-selector {
            height: 30px !important;
            margin: 0px !important;
        }
        input[type="search"] {
            border-radius: 0px !important;
        }
        .input-select .ant-select .ant-select-selector {
            border-radius: 0px !important;
        }
    }
    .w-label {
        width: 100px;
        font-weight: bold;
        margin-top: 8px;
    }
   
    .btn-login {
        background-color: #d90044;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        display: flex;
        align-items: center;
    }
    .link-quenmatkhau {
        text-decoration: underline;
        font-style: italic;
        color: #0067ac;
    }
}
.inputrow{
    width: 100%;

}
.inputrow .form-row-login{
    height: 34px !important;
}
.logindisplay{
    display: none;
}
.btn-login:hover{
    color: #000 !important;
}
.form-control-login-thoigian{
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 3px 5px;
    outline: 1px;
    font-size: 12px;
}
.react-datepicker-wrapper{
    width: 100%;
}
.bg-login .ant-form-item .ant-form-item-label > label::after{ margin-top: 6px;}
.bg-login .ant-select-arrow {top: 50% !important;}