.webcam {
    overflow: scroll;
    width: 100%;
    height: 380px !important;
}

.webcam::-webkit-scrollbar {
    display: none;
}
.webcam {
    scrollbar-width: auto;
}
.ant-upload.ant-upload-select {
    border-radius: 0px !important;
}
.box-image {
    position: relative;
    z-index: 0;
    width: 100px;
    height: 100px;
    margin: 5px;
    .btn-close-webcam {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        .btn-icon-close {
            width: 15px;
            padding: 0px;
            height: 15px;
            border-radius: 50%;
            border: silver;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.8;
            // svg{
            //     font-size: 11px;
            // }
            &:hover {
                background-color: red;
                svg {
                    color: #fff !important;
                }
            }
        }
    }
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        font-size: 10px;
        div {
            background-color: #fff;
            border-radius: 25px;
            padding: 0px 10px;
            opacity: 0.8;
        }
    }
    .cb-img {
        position: absolute;
        bottom: -9px;
        right: 0;
        z-index: 1;
        .cb {
            height: 10px !important;
        }
    }
}
