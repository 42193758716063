li.ant-menu-item.ant-menu-item-only-child {
    line-height: normal !important;
    height: max-content !important;
    padding: 2px;
    font-weight: 500;
    font-size: 12px;
}

//menu ngang
.duoc-menu {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
