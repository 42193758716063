.pttt-form-input-label {
    width: 80px;
}
.pttt-form-input-label-col-4 {
    width: 30px;
}
.pttt-form-input-label-right {
    width: 120px;
}
.tab-khambenh {
    //tab
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff !important;
        font-weight: bold;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        background-color: #0067ac !important;
        border-radius: 0px !important;
    }
    .ant-tabs-tab {
        color: #0067ac !important;
        border-radius: 0px !important;
        border: 2px solid #0067ac !important;
        padding: 0px 10px !important;
        margin: 0px;
    }
    .ant-tabs-nav {
        margin: 0px !important;
    }
}
.border-line-left {
    border-left: 1px solid silver;
    height:180px;
    
}
.pttt-lichsu{
    margin:0px 5px;
}
.pttt-sinhhieu{
     border-left: 1px solid silver;
     height: 200px;
        
}