li.ant-menu-item.ant-menu-item-only-child {
    line-height: normal !important;
    height: max-content !important;
    padding: 2px;
    font-weight: 500;
    font-size: 12px;
}

//menu ngang
.vienphi-menu {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;}
.menu-link-vienphi {
    .menu-navlink{
        margin: 0px 2px;
    }
    a {
        color: #0067ac !important;
        padding: 2px 10px;
        
        &:hover {
            background-color: #0067ac;
            color: #fff !important;
        }
    }
    a.active {
        background-color: #0067ac !important;
        color: #fff !important;
    }
}
