.scroll-phanquyen-1 {
    overflow-y: scroll;
    height: 100vh;
}
.scroll-phanquyen-1::-webkit-scrollbar {
    display: none;
}
.scroll-phanquyen-1 {
    scrollbar-width: none;
}
.scroll-phanquyen-2 {
    overflow-y: scroll;
    height: 50vh;
}
.scroll-phanquyen-2::-webkit-scrollbar {
    display: none;
}
.scroll-phanquyen-2 {
    scrollbar-width: none;
}
.ant-tree-treenode {
    height: 18px !important;
}

.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}
.btn-icon {
    border-radius: 2px;
    margin: 0px 2px;
    padding: 5px 15px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #0067ac 1px solid;
    background-color: #0080d6;
    svg {
        color: #fff !important;
    }
    &:hover{
        svg{
            color: #000 !important;
        }
    }
}
