.khambenh-phieukham-right-input-label {
    width: 85px;
}
.khambenh-phieukham-left-input-label {
    width: 88px;
}
.khambenh-phieukham-right-input-label-cannang {
    width: 60px;
}
.border-input {
    border: none !important;
}
.tabphieukham-position {
    position: relative;
}
.phieukham-input-position {
    position: absolute;
    right: 0;
    top: -5px;
    z-index: 1;
}
.khambenh .ant-select-selector {
    height: 21px !important;
    margin: 6px 0px !important;
}

.khambenh .ant-select-arrow {
    top: 55% !important;
}
.khambenh .select-khb{
    margin-bottom: 5px;
}
.border-line-left-kb{
    border-left: 1px solid #ccc;
    padding-left: 4px;
    margin-left:4px;
}
.kb-loidan{
    margin-bottom: 15px;
}
.kb-loidan .ant-form-item-control-input{
    min-height: 0px !important;
}
.khambenh-label label{
    margin-top: 8px;
    height: auto !important;
}

.khambenh-label .kb-loidan label{

    margin-top: -8px;
}
.pk-tt-row label{
    float: left;
    width: 31%;
    font-size: 12px;
    text-align: right;
    padding-right: 5px;
}
.pk-tt-row input{
    float: left;
    width: 69%;
}
.phieukham-input-position{
    margin-top: 5px;
}
.ant-modal-body iframe{
    width: 100%;
    height: 600px;
}

