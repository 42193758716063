//form
/*.tiepnhan-timkiem-form {
    border: 2px solid #0067ac;
    padding: 6px;
}*/

//select
.tiepnhan-timkiem-form .ant-select-selector {
    margin: 0px 0px !important;
    //height: 20px !important;
}
.tiepnhan-timkiem-form .ant-select-arrow {
    top: 50% !important;
    }
input[type="search"] {
    height: 25px !important;
    border-radius: 0px !important;
}
.input-select .ant-select .ant-select-selector {
    border-radius: 0px !important;
    font-size: 9px;
}
.tiepnhan-timkiem-form label::after{
    content: "" !important;
}
.tiepnhan-tk-table{
    margin-left: 5px;
    margin-right: 5px;
    width: 99%;
}
.tiepnhan-row{
    margin: 5px 5px;
    width: 99%;
 }

 .m-1.tn-from{
     margin: 0px !important;
 }
 .b-content{
     margin: 0px !important;
 }