.box-lichsu {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 15px 5px;
    margin: 5px 8px;
}
.img-lichsudangnhap {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid rgb(225, 225, 225);
    display: flex;
    justify-content: center;
}
.noidung-lichsudangnhap {
    div {
        font-size: 13px;
        padding: 5px 20px;
        .label {
            font-weight: bold;
            color: rgb(109, 109, 109);
            text-align: right;
        }
        .content {
            margin-left: 20px;
        }
    }
}
