.vienphi-border {
    border: 2px solid #0067ac;
    padding-bottom: 10px;
}
.vienphi-title{
    font-size: 12px;
    font-weight: bold;
}
.tab-vienphi {
    //tab
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff !important;
        font-weight: bold;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        background-color: #0067ac !important;
        border-radius: 0px !important;
    }
    .ant-tabs-tab {
        color: #0067ac !important;
        border-radius: 0px !important;
        border: 2px solid #0067ac !important;
        padding: 0px 10px !important;
        margin: 0px;
    }
    .ant-tabs-nav {
        margin: 0px !important;
    }
}