.inphieudtri {
    padding: 10px;
}
.header{
    width: 100%;
    display: flex;
}
.inphieudtri .header .header-left{
    float: left;
    width: 25%;
}
.inphieudtri .header .header-content{
    float: left;
    width: 50%;
    text-align: center;
}
.inphieudtri .header .header-right{
    float: left;
    width: 25%;
}
.thongtin-todieutri ul{
    margin: 0px;
    list-style: none;
    padding: 0px;
    font-size: 13px;
}
.thongtin-todieutri ul li{
    display: inline-block;
    width: 100%;
}
.thongtin-todieutri ul li div{
    float: left;
}
.thongtin-todieutri ul li div.hoten{
    width: 60%;
}
.thongtin-todieutri ul li div.tuoi{
    width: 20%;
}
.thongtin-todieutri ul li div.gioitinh{
    width: 20%;
}
.inphieudtri .ketquatodieutri table{
    width: 100%;
}
.inphieudtri .ketquatodieutri table th td{
    border: 1px solid #ccc;
    padding: 6px;
    vertical-align:middle;
    text-align: center;
}
.inphieudtri .ketquatodieutri .ngay{
    width: 10%;
}
.inphieudtri .ketquatodieutri .giophut{
    width: 10%;
}
.inphieudtri .ketquatodieutri .dienbienbenh{
    width: 40%;
}
.inphieudtri .ketquatodieutri .ylenhdieutri{
    width: 40%;
}