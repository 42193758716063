//btn menu thao tác

.color-icon {
    svg {
        color: #0067ac !important;
    }
}
.color-icon-edit {
    svg {
        color: #ffc107 !important;
    }
}

.tiepnhan-tab {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.tiepnhan-title {
    background-color: #0067ac;
    color: white;
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 13px;
}
.tiepnhan-form-right {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
}
.tiepnhan-phongkham-box {
    width: 18%;
    font-size: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    margin: 10px;
    .tiepnhan-phongkham-box-title {
        background-color: #0c79c1;
        color: white;
        padding: 8px;
        border-radius: 5px;
    }
}

.tiepnhan-btn-thongtinkhambenh {
    position: fixed;
    right: 10px;
    top: 40px;
    button {
        background-color: #0067ac !important;
    }
}
