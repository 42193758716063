.w-5 {
    width: 5% !important;
}
.w-10 {
    width: 10% !important;
}
.w-15 {
    width: 15% !important;
}
.w-20 {
    width: 20% !important;
}
.w-30 {
    width: 30% !important;
}
.w-35 {
    width: 35% !important;
}
.w-40 {
    width: 40% !important;
}
.w-45 {
    width: 45% !important;
}
.w-55 {
    width: 55% !important;
}
.w-60 {
    width: 60% !important;
}
.w-65 {
    width: 65% !important;
}
.w-70 {
    width: 70% !important;
}
.w-80 {
    width: 80% !important;
}
.w-85 {
    width: 85% !important;
}
.w-90 {
    width: 90% !important;
}
.w-95 {
    width: 95% !important;
}
.h-5 {
    height: 5% !important;
}
.h-10 {
    height: 10% !important;
}
.h-15 {
    height: 15% !important;
}
.h-20 {
    height: 20% !important;
}
.h-30 {
    height: 30% !important;
}
.h-35 {
    height: 35% !important;
}
.h-40 {
    height: 40% !important;
}
.h-45 {
    height: 45% !important;
}
.h-55 {
    height: 55% !important;
}
.h-60 {
    height: 60% !important;
}
.h-65 {
    height: 65% !important;
}
.h-70 {
    height: 70% !important;
}
.h-80 {
    height: 80% !important;
}
.h-85 {
    height: 85% !important;
}
.h-90 {
    height: 90% !important;
}
.h-95 {
    height: 95% !important;
}
