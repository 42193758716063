.danhsachleft-btn {
    svg {
        font-size: 10px;
    }
    border-radius: 0px;
    padding: 0px;
    width: 35px;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vienphi-danhsach-left {
    position: relative;
}
.vienphi-danhsach-open-left {
    width: 30px;
    height: 50px;
    z-index: 3;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248);
    position: absolute;
    right: -30px;
    top: 180px;
    transition: all 0.5s ease-out;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}

.vienphi-danhsach-close-left {
    position: absolute;
    right: 0px;
    top: 180px;
    transition: all 1s ease-out;
    width: 30px;
    height: 50px;
    position: absolute;
    z-index: 3;
    left: 0;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 180px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248);
    transition: all ease-in;
    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}
.vienphi-danhsach-left-close {
    transition: all 1s ease-out;
    display: none;
}
.vienphi-danhsach-left-open {
    transition: all 1s ease-out;
}
.vp-left-top-date{
    margin-right: 0px !important;
}
.vp-left-top-date label::after{
 content: "" !important;
}