.btn-nhapkho {
    position: relative;
}

.btn-pos {
    position: absolute;
    right: 0;
    top: -30px;
}

.btn-pos button {
    float: left;
}

.btn-pos .form-btn {
    padding: 10px 10px !important;
}
.title-nhapkho{
    font-size: 14px;
    font-weight: bold;
    padding: 10px 10px;
}
.tt-xemtontreo{
    background: #f5f5f5;
    padding-top: 10px;
    padding-left: 10px;
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
}
.tt-xemtontreo .lablename-nhapkho {
    font-size: 12px;
    float: left;
    width: 20%;
    text-align: right;
    padding-right: 5px;
    margin-top: 5px;
}

.tt-xemtontreo .txtinput {
    font-size: 12px;
    float: left;
    width: 80%;
}

.tt-xemtontreo .txtinput input {
    height: 26px;
}



.tt-xemtontreo .row>* {
    padding: 0px 1px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.tt-xemtontreo .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.row-m {
    margin-top: 5px !important;
    margin-bottom: 5px;
}
.btn-tontreo{
    float: left;
    margin-left: 5px;
}
